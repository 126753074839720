.card {
  position: relative;
  width: 100%;
  height: fit-content;
  /* background: #dedede; */
  border-radius: 10px;
}

.card img {
  border-radius: 10px;
  position: relative;
  width: 100%;
  /* aspect-ratio: 1 / 0.9; */
}

.cardImg {
  width: 100%;
  position: relative;
  border-radius: 10px;
  aspect-ratio: 1 / 0.9;
  /* background-image: url("../../assets/images/house.webp"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  background-color: #9f9f9f00;
}

.listcard {
  width: 95%;
  margin: 0 auto;
  padding-bottom: 50px;
}

.likeBtn {
  position: absolute;
  color: red;
  top: 10px;
  right: 10px;
  z-index: 2;
  cursor: pointer;
}

.cardFooter {
  text-align: left !important;
  padding: 10px 0;
}

.card .slick-disabled {
  display: none !important;
}
.card .slick-slide {
  position: relative;
}

.card .slick-dots li,
.card .slick-dots li button {
  margin: 0;
  padding: 0;
}

.card .slick-dots li.slick-active button:before {
  opacity: 1;
  color: white;
}
.card .slick-dots li button:before {
  color: #ffffff;
  opacity: 0.5;
}
.card .slick-dots {
  position: absolute;
  bottom: 35px;
  display: block;
  color: #ffffff;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.card .slick-prev {
  left: 20px;
  z-index: 10;
}
.card .slick-next {
  right: 20px;
  z-index: 10;
}
